import React from "react";

const Terms = () => {
  return (
    <div id="body">
      <section>
        <h1>Điều Khoản Sử Dụng</h1>
        <p>
          Chào mừng bạn đến với Elula! Trước khi sử dụng trang web của chúng
          tôi, vui lòng đọc kỹ các điều khoản sử dụng sau đây:
        </p>
        <h2>1. Điều Khoản Chung</h2>
        <p>
          Khi sử dụng Elula, bạn đồng ý tuân thủ tất cả các quy định và luật
          pháp liên quan. Bạn không được sử dụng trang web này cho bất kỳ mục
          đích bất hợp pháp nào.
        </p>
        <h2>2. Quyền Sở Hữu Trí Tuệ</h2>
        <p>
          Tất cả nội dung trên Elula, bao gồm văn bản, đồ họa, logo, biểu tượng,
          hình ảnh, clip âm thanh, tải xuống kỹ thuật số, dữ liệu biên dịch, và
          phần mềm đều thuộc sở hữu của Elula hoặc các nhà cung cấp nội dung và
          được bảo vệ bởi luật sở hữu trí tuệ.
        </p>
        <h2>3. Quyền Riêng Tư</h2>
        <p>
          Elula cam kết bảo vệ quyền riêng tư của bạn. Chúng tôi sẽ không tiết
          lộ thông tin cá nhân của bạn cho bất kỳ bên thứ ba nào mà không có sự
          đồng ý của bạn, ngoại trừ theo yêu cầu của pháp luật.
        </p>
        <h2>4. Trách Nhiệm Của Người Dùng</h2>
        <p>
          Bạn chịu trách nhiệm cho tất cả các hoạt động xảy ra dưới tài khoản
          của bạn và phải bảo mật thông tin đăng nhập của mình. Nếu bạn phát
          hiện bất kỳ hoạt động bất thường nào trong tài khoản của mình, vui
          lòng thông báo ngay cho chúng tôi.
        </p>
        <h2>5. Thay Đổi Điều Khoản</h2>
        <p>
          Elula có quyền thay đổi các điều khoản này bất cứ lúc nào. Các thay
          đổi sẽ được thông báo trên trang web của chúng tôi. Việc tiếp tục sử
          dụng trang web của bạn sau khi các thay đổi được đăng tải có nghĩa là
          bạn đồng ý với các điều khoản mới.
        </p>
        <p>
          Cảm ơn bạn đã sử dụng Elula. Nếu bạn có bất kỳ câu hỏi nào về các điều
          khoản này, vui lòng liên hệ với chúng tôi.
        </p>
      </section>
    </div>
  );
};

export default Terms;
