import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import Home from "./Home";
import Terms from "./Terms.js";
import Privacy from "./Privacy/index.js";
import NavBar from "./NavBar/index.js";
import Footer from "./Footer/index.js";
import DeleteAccountForm from "./DeleteAccount/index.js";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <div>
          <Routes>
            {/* Các Route cho các trang có NavBar và Footer */}
            <Route
              path="/"
              element={
                <>
                  <NavBar />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
            </Route>

            {/* Route cho trang delete-account chỉ có DeleteAccountForm */}
            <Route path="/delete-account" element={<DeleteAccountForm />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
