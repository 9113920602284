import React from "react";

const Privacy = () => {
  return (
    <div id="body">
      <section>
        <h1>Chính Sách Bảo Mật</h1>
        <h2>1. Mục Đích</h2>
        <p>
          Chính sách bảo mật này mô tả cách chúng tôi thu thập, sử dụng và bảo
          vệ thông tin cá nhân của bạn khi bạn sử dụng dịch vụ của chúng tôi.
        </p>
      </section>

      <section>
        <h2>2. Thông Tin Thu Thập</h2>
        <p>
          Chúng tôi có thể thu thập thông tin cá nhân của bạn khi bạn đăng ký
          tài khoản, sử dụng dịch vụ hoặc tham gia các hoạt động khác trên trang
          web của chúng tôi.
        </p>
      </section>

      <section>
        <h2>3. Sử Dụng Thông Tin</h2>
        <p>
          Thông tin cá nhân của bạn có thể được sử dụng để cung cấp dịch vụ, cá
          nhân hóa trải nghiệm của bạn, và gửi thông tin về sản phẩm và dịch vụ
          của chúng tôi.
        </p>
      </section>

      <section>
        <h2>4. Bảo Mật</h2>
        <p>
          Chúng tôi cam kết bảo vệ thông tin cá nhân của bạn và sử dụng các biện
          pháp bảo mật phù hợp để bảo vệ thông tin đó khỏi mất mát, lạm dụng
          hoặc truy cập trái phép.
        </p>
      </section>

      <section>
        <h2>5. Liên Hệ</h2>
        <p>
          Nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào liên quan đến Chính Sách
          Bảo Mật của chúng tôi, hãy liên hệ với chúng tôi qua email{" "}
          <a href="mailto:privacy@elula.com">privacy@elula.com</a>.
        </p>
      </section>

      <section>
        <h2>6. Quyền Lợi Của Bạn</h2>
        <p>
          Bạn có quyền kiểm soát thông tin cá nhân của mình và có thể yêu cầu
          chúng tôi sửa đổi hoặc xóa thông tin đó khỏi hệ thống của chúng tôi.
          Chúng tôi sẽ thực hiện các biện pháp của mình.
        </p>
      </section>
    </div>
  );
};

export default Privacy;
