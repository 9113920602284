import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ConfirmPopup from "../Confirmpopup";
import { showErrorToast, showSuccessToast } from "../useCustomToast";
import { API_URL } from "../shared/setting";

const DeleteAccountPage = () => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { openPopup, ConfirmationPopup } = ConfirmPopup();

  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .trim()
      .required("Số điện thoại không hợp lệ!")
      .matches(
        /^(?:\+?84|0?)?(3[2-9]|5[2689]|7[06-9]|8[1-9]|9[0-9])\d{7}$/,
        "Số điện thoại không hợp lệ!"
      )
      .nullable(),
    password: yup
      .string()
      .required("Password không hợp lệ!")
      .min(8, "Password có ít nhất 8 kí tự")
      .matches(/^(\S+$)/, "Password không hợp lệ!")
      .nullable(),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: "",
      password: "",
    },
  });

  const deleteAccount = async (data) => {
    try {
      const response = await fetch(`${API_URL}/api/Account/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: data.phoneNumber,
          password: data.password,
        }),
      });

      if (response.ok) {
        reset();
        showSuccessToast("Yêu cầu xóa tài khoản thành công");
        setShowPassword(false);
      } else {
        const errorData = await response.json();
        showErrorToast(
          errorData?.message || "Yêu cầu xóa tài khoản không thành công"
        );
      }
    } catch (error) {
      showErrorToast("Có lỗi xảy ra, vui lòng thử lại");
    }
  };

  const onSubmit = (data) => {
    openPopup("Bạn có chắc chắn muốn xóa tài khoản không?", () =>
      deleteAccount(data)
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              mt: 4,
              p: 3,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h4" gutterBottom fontWeight="bold">
              XÓA TÀI KHOẢN
            </Typography>
            <Typography variant="body1" paragraph>
              Khi bạn xóa tài khoản, bạn sẽ mất quyền truy cập vào tất cả các
              dịch vụ và dữ liệu liên quan. Hãy chắc chắn rằng bạn sẽ muốn xóa
              tài khoản này vĩnh viễn.
            </Typography>
            <Typography variant="body1" paragraph>
              Sau khi xóa tài khoản, tài khoản của bạn sẽ bị xóa vĩnh viễn khỏi
              hệ thống và không thể khôi phục.
            </Typography>
            <Typography variant="body1" paragraph>
              Vui lòng điền số điện thoại và mật khẩu để xác nhận xóa tài khoản
              vĩnh viễn.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Số điên thoại"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        error={!!error}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2, backgroundColor: "#007aff" }}
                    onClick={handleClickOpen}
                  >
                    Xóa tài khoản
                  </Button>
                </Grid>
              </Grid>
            </form>
            <ConfirmationPopup />
            {/* <CustomBackdrop open={dataDeleteAccount?.loadingDeleteCustomer} /> */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeleteAccountPage;
