import React from "react";

const Home = () => {
  return (
    <div id="body">
      <h1>Chào mừng đến với Elula</h1>
      <p>
        Elula là nền tảng mạng xã hội hiện đại và thú vị, giúp bạn kết nối và
        chia sẻ với bạn bè, gia đình và thế giới xung quanh.
      </p>
      <h2>Khám Phá Elula</h2>
      <p>
        Elula không chỉ là nơi để chia sẻ những khoảnh khắc đẹp nhất của bạn mà
        còn là một nền tảng để bạn kết nối và tương tác với bạn bè và gia đình.
      </p>
      <p>
        Bạn có thể tìm kiếm và kết nối với bạn bè cũng như theo dõi những người
        bạn quan tâm. Bạn cũng có thể tham gia vào các nhóm cộng đồng với các sở
        thích và sự quan tâm chung, và thảo luận về các chủ đề yêu thích của
        bạn.
      </p>
      <p>
        Chức năng "Tin Tức" của Elula cung cấp cho bạn thông tin mới nhất và các
        cập nhật từ bạn bè, trang và nhóm bạn đang theo dõi.
      </p>
      <h2>Về Elula</h2>
      <p>
        Elula là một mạng xã hội mới mẻ, mang đến cho người dùng trải nghiệm kết
        nối tuyệt vời và tiện ích. Với Elula, bạn có thể:
      </p>
      <ul>
        <li>Kết nối với bạn bè và người thân một cách dễ dàng.</li>
        <li>Chia sẻ hình ảnh, video và các khoảnh khắc đáng nhớ của bạn.</li>
        <li>
          Tham gia vào các nhóm cộng đồng và thảo luận về các chủ đề yêu thích.
        </li>
        <li>
          Theo dõi các trang và nhận thông tin mới nhất từ những người mà bạn
          quan tâm.
        </li>
      </ul>
      <p>
        Elula cam kết bảo vệ quyền riêng tư của người dùng và tạo ra một môi
        trường trực tuyến an toàn và lành mạnh.
      </p>
    </div>
  );
};

export default Home;
