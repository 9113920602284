import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-content">
        <h3>Công ty TNHH Công nghệ Platinum</h3>
        <p>
          <i className="fas fa-map-marker-alt"></i> Trụ sở chính: Lô E1-28 đường
          số 2, khu đô thị Phú Mỹ An, phường An Đông, TP Huế
        </p>
        <p>
          <i className="fas fa-envelope"></i> Email:{" "}
          <a href="mailto:career@platinumtechnology.net">
            career@platinumtechnology.net
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
