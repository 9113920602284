import React, { useState } from "react";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState(""); // State to manage active link

  // Function to handle click on navigation links
  const handleClick = (path) => {
    setActiveLink(path); // Update activeLink state with clicked path
  };

  return (
    <nav id="navMain">
      <ul>
        <li>
          <a
            href="/"
            className={`nav-link ${activeLink === "/" ? "active" : ""}`}
            onClick={() => handleClick("/")}
          >
            <i className="fas fa-home"></i> TRANG CHỦ
          </a>
        </li>
        <li>
          <a
            href="/terms"
            className={`nav-link ${activeLink === "/terms" ? "active" : ""}`}
            onClick={() => handleClick("/terms")}
          >
            <i className="fas fa-file-alt"></i> ĐIỀU KHOẢN SỬ DỤNG
          </a>
        </li>
        <li>
          <a
            href="/privacy"
            className={`nav-link ${activeLink === "/privacy" ? "active" : ""}`}
            onClick={() => handleClick("/privacy")}
          >
            <i className="fas fa-lock"></i> CHÍNH SÁCH BẢO MẬT
          </a>
        </li>
        <li>
          <a
            href="/news"
            className={`nav-link ${activeLink === "/news" ? "active" : ""}`}
            onClick={() => handleClick("/news")}
          >
            <i className="fas fa-newspaper"></i> TIN TỨC
          </a>
        </li>
        <li>
          <a
            href="/contact"
            className={`nav-link ${activeLink === "/contact" ? "active" : ""}`}
            onClick={() => handleClick("/contact")}
          >
            <i className="fas fa-envelope"></i> LIÊN HỆ
          </a>
        </li>
        <li>
          <a
            href="/announcement"
            className={`nav-link ${
              activeLink === "/announcement" ? "active" : ""
            }`}
            onClick={() => handleClick("/announcement")}
          >
            <i className="fas fa-bullhorn"></i> THÔNG BÁO
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
